<template>
    <div id="constructor-label" class="constructor-box__list-item constructor-box__list-item_badge">
        <div
            class="constructor-box__list-head collapsed" data-toggle="collapse"
            data-target="#constructor-item-tag">
            <div class="constructor-box__list-label">{{$t('tag.title')}}:</div>
            <div v-if="store.getters.getSelectedParamsBySteps.Checkout.label"
                 class="constructor-box__list-selected" v-text="getActiveLabelText"></div>
            <div v-else class="constructor-box__list-selected">{{$t('tag.choose')}}</div>
            <div class="constructor-box__list-icon"></div>
        </div>
        <div class="constructor-box__list-drop collapse" id="constructor-item-tag">
            <div class="constructor-box__list-body">
                <div class="choose-badge">
                    <div class="choose-badge__header">
                        <div class="choose-badge__label">{{$t('tag.for')}}:</div>
                        <div class="choose-badge__params">
                            <div
                                v-for="opt in labelType"
                                class="choose-badge__params-item">
                                <div class="radio">
                                    <label>
                                        <input type="radio" name="contructor-badge"
                                               @change="setLabel(this, true)"
                                               v-model="formData.labelType"
                                               :value="opt.value"
                                               :checked="getActiveLabel.value === opt.value ?  'checked' : ''"/>
                                        <span v-text="$t('tag.labelType.'+opt.value)"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="choose-badge__body">
                        <div class="choose-badge__col">
                            <div
                                v-if="['personal', 'for-person'].includes(formData.labelType)"
                                class="choose-badge__gender">
                                <div class="form-group">
                                    <label class="control-label">{{$t('tag.gender')}}:</label>
                                    <div class="radio radio_inline">
                                        <label>
                                            <input type="radio" name="contructor-badge-gender"
                                                   @change="setLabel"
                                                   v-model="formData.gender"
                                                   value="female"
                                                   data-title="Витворила"/>
                                            <span>{{$t('tag.female')}}</span>
                                        </label>
                                    </div>
                                    <div class="radio radio_inline">
                                        <label>
                                            <input type="radio"
                                                   @change="setLabel"
                                                   v-model="formData.gender"
                                                   name="contructor-badge-gender"
                                                   value="male"
                                                   data-title="Витворив"/>
                                            <span>{{$t('tag.male')}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="['personal', 'for-person'].includes(formData.labelType)"
                                class="choose-badge__name">
                                <div class="form-group">
                                    <label class="control-label" for="field-constructor-badge-name">
                                        {{$t('tag.your_name')}}:
                                    </label>
                                    <input
                                        v-model="formData.name"
                                        @change="setLabel"
                                        class="form-control" id="field-constructor-badge-name"
                                        type="text" :placeholder="$t('tag.placeholder')"
                                        maxlength="25"/>
                                </div>
                            </div>
                            <div
                                v-if="['for-person', 'dedication'].includes(formData.labelType)"
                                class="choose-badge__secondname">
                                <div class="form-group">
                                    <label class="control-label" for="field-constructor-badge-secondname">
                                        {{$t('tag.for')}}:
                                    </label>
                                    <input
                                        v-model="formData.friendName"
                                        @change="setLabel"
                                        class="form-control"
                                        id="field-constructor-badge-secondname"
                                        type="text"
                                        :placeholder="$t('tag.placeholder')" maxlength="25"/>
                                </div>
                            </div>
                        </div>
                        <div class="choose-badge__col">
                            <div class="choose-badge__label">{{$t('tag.view')}}:</div>
                            <div class="choose-badge__view">
                                <div class="choose-badge__view-content">
                                    <div class="choose-badge__view-sign">
                                        <img class="choose-badge__badge-view-sign-i"
                                             data-src="/assets/i/vytvory-sign.svg"
                                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                             alt="" role="presentation"/>
                                    </div>
                                    <div class="choose-badge__view-label">
                                        <template v-if="pageLang == 'uk'">
                                            <svg width="190" height="21" viewBox="0 0 190 21"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <defs>
                                                    <path id="a" d="M.011.393h19.088v19.038H.011z"/>
                                                </defs>
                                                <g fill="none" fill-rule="evenodd">
                                                    <path
                                                        d="M15.631 10.09c1.06-.924 1.702-2.136 1.702-3.608 0-2.828-2.062-4.999-7.147-4.999C3.792 1.483 0 4.936 0 10.203v9.834h6.673V9.38c0-4.347 5.313-4.926 5.313-2.508 0 1.728-2.9 1.541-2.9 1.541l.014 3.722c1.38 0 4.448-.055 4.448 1.828 0 2.466-4.244 1.63-4.244 1.63v3.857s1.955 1.075 4.518 1.075c3.47 0 5.762-2.675 5.762-5.474 0-2.079-1.647-3.986-3.953-4.962M78.57 10.09c1.06-.924 1.703-2.136 1.703-3.608 0-2.828-2.062-4.999-7.147-4.999-6.394 0-10.186 3.453-10.186 8.72v9.834h6.673V9.38c0-4.347 5.313-4.926 5.313-2.508 0 1.728-2.9 1.541-2.9 1.541l.014 3.722c1.379 0 4.448-.055 4.448 1.828 0 2.466-4.244 1.63-4.244 1.63v3.857s1.955 1.075 4.518 1.075c3.469 0 5.762-2.675 5.762-5.474 0-2.079-1.647-3.986-3.953-4.962"
                                                        fill="#E84734"/>
                                                    <g transform="translate(43 .607)">
                                                        <mask id="b" fill="#fff">
                                                            <use xlink:href="#a"/>
                                                        </mask>
                                                        <path
                                                            d="M19.099 5.066L17.379.393c-.03.01-2.999 1.07-7.088 1.07H.011v4.98h5.775V19.43h6.674V6.359c3.87-.29 6.51-1.245 6.639-1.293"
                                                            fill="#E84734" mask="url(#b)"/>
                                                    </g>
                                                    <path
                                                        d="M27.921 11.875c0-6.923-1.728-9.805-1.728-9.805l-5.767 2.575s1.027 1.031 1.027 6.41c0 5.376-.47 9.47 3.46 9.47 5.014 0 9.45-10.42 9.45-10.42v9.932h6.207V9.157c0-3.907.224-7.087-3.065-7.087-4.458 0-8.247 9.805-9.584 9.805M135.32 11.875c0-6.923-1.729-9.805-1.729-9.805l-5.767 2.575s1.027 1.031 1.027 6.41c0 5.376-.47 9.47 3.46 9.47 5.014 0 9.45-10.42 9.45-10.42v9.932h6.207V9.157c0-3.907.224-7.087-3.065-7.087-4.458 0-8.247 9.805-9.584 9.805M116.44 14.451c4.472 0 9.95-1.56 9.95-6.842 0-3.969-3.59-6.126-8.675-6.126-6.395 0-10.187 3.453-10.187 8.72v9.834h6.674V9.38c0-4.346 5.544-4.068 5.544-1.403 0 2.726-3.32 2.752-3.32 2.752l.014 3.722zM91.698 7.49c-.795.797-1.232 2.13-1.232 3.646 0 1.514.437 2.849 1.232 3.645.616.59 1.643 1.052 2.798 1.052s2.181-.462 2.798-1.052c.795-.796 1.232-2.131 1.232-3.645 0-1.515-.437-2.85-1.232-3.645-.617-.59-1.643-1.052-2.798-1.052s-2.182.462-2.798 1.052m11.063 10.549c-1.823 1.566-4.697 2.567-8.265 2.567-3.568 0-6.443-1.001-8.265-2.567-1.206-1.026-2.952-3.156-2.952-6.904 0-3.748 1.746-5.878 2.952-6.905 1.822-1.565 4.697-2.566 8.265-2.566 3.568 0 6.442 1 8.265 2.566 1.206 1.027 2.951 3.157 2.951 6.905s-1.745 5.878-2.95 6.904"
                                                        fill="#E84734"/>
                                                    <g
                                                        :class="{active: formData.gender === 'female'}"
                                                        class="badge-label badge-label-female" stroke="#E84734"
                                                        stroke-width=".611">
                                                        <path
                                                            d="M168.36 19.93l-4.4-17.94h-6.675c-5.854 3.98-7.85 12.835-7.85 17.94h5.254c0-6.476 1.906-10.008 4.047-12.023l2.95 12.024h6.673zM175.85 14.078c.736-2.942 2.02-4.872 3.398-6.171l1.514 6.17h-4.913zm6.348 5.853h6.672l-4.399-17.94h-6.674c-5.853 3.98-7.849 12.834-7.849 17.94h5.254c0-1.018.046-1.962.134-2.838h6.165l.697 2.838z"/>
                                                    </g>
                                                    <path
                                                        :class="{active: formData.gender === 'male'}"
                                                        class="badge-label badge-label-male"
                                                        d="M159.346 1.45c-6.394 0-10.185 3.453-10.185 8.72v9.834h6.672V9.347c0-2.676 2.014-3.924 3.563-3.924.967 0 1.752.486 1.752 1.416 0 1.421-1.963 1.547-2.66 1.547-.15 0-.243-.006-.243-.006l.016 3.722h.24c1.466 0 4.207.05 4.207 1.828 0 1.47-1.507 1.767-2.725 1.767-.826 0-1.519-.137-1.519-.137v3.857s1.956 1.075 4.52 1.075c3.468 0 5.76-2.675 5.76-5.474 0-2.078-1.647-3.986-3.952-4.962 1.059-.923 1.702-2.135 1.702-3.607 0-2.828-2.063-4.999-7.148-4.999m0 .6c4.222 0 6.547 1.563 6.547 4.4 0 1.228-.502 2.29-1.495 3.154l-.725.631.885.374c2.179.922 3.587 2.652 3.587 4.41 0 2.395-1.931 4.873-5.162 4.873-1.798 0-3.294-.572-3.918-.85v-2.793a9.1 9.1 0 00.918.048c3.001 0 3.326-1.655 3.326-2.367 0-2.245-2.88-2.415-4.45-2.427l-.01-2.526c1.823-.09 2.898-.875 2.898-2.138 0-1.187-.967-2.016-2.352-2.016-1.728 0-4.16 1.403-4.16 4.524v10.057h-5.475V10.17c0-5.008 3.674-8.119 9.587-8.119"
                                                        fill="#E84734"/>
                                                </g>
                                            </svg>
                                        </template>
                                        <template v-else-if="pageLang == 'en'">
                                            <svg id="_Шар_2" data-name="Шар 2" xmlns="http://www.w3.org/2000/svg" width="190" height="21" viewBox="0 0 190 21">
                                                <g id="_L_1_2" data-name="L-1">
                                                    <g>
                                                        <path fill="#f9423a" d="m57.76,5.01l-1.59-4.31s-2.76.99-6.53.99h-9.47v4.59h5.32v11.97h6.15V6.2c3.57-.27,6-1.15,6.12-1.19Z"/>
                                                        <path fill="#f9423a" d="m114.44,11.9c2.21-.78,3.9-2.29,3.9-5.1,0-3.66-3.31-5.64-8-5.64-5.89,0-9.39,3.18-9.39,8.03v9.06h6.15v-9.82c0-4.01,5.11-3.75,5.11-1.29s-3.06,2.54-3.06,2.54v2.97s2.91,2.38,5.2,7.29l5.32-2.37c-1.48-2.9-3.54-4.65-5.23-5.67Z"/>
                                                        <path fill="#f9423a" d="m96.56,16.33c-1.68,1.44-4.33,2.37-7.62,2.37s-5.94-.92-7.62-2.37c-1.11-.95-2.72-2.91-2.72-6.36s1.61-5.42,2.72-6.36c1.68-1.44,4.33-2.37,7.62-2.37s5.94.92,7.62,2.37c1.11.95,2.72,2.91,2.72,6.36s-1.61,5.42-2.72,6.36Zm-10.19-9.72c-.73.73-1.14,1.96-1.14,3.36s.4,2.63,1.14,3.36c.57.54,1.51.97,2.58.97s2.01-.43,2.58-.97c.73-.73,1.14-1.96,1.14-3.36s-.4-2.63-1.14-3.36c-.57-.54-1.51-.97-2.58-.97s-2.01.43-2.58.97Z"/>
                                                        <path fill="#f9423a" d="m32.32,1.69c-.04,3.43-.57,5.99-1.43,7.86-1.54-.69-3.9-2.59-3.9-7.86h-6.13c-.4,8.29,5.08,10.32,7.79,10.82-1.67,1.31-3.75,1.63-5.93,1.37l-.11,4.37c10.33,1.92,15.87-6.83,15.87-16.56h-6.17Z"/>
                                                        <path fill="#f9423a" d="m131.14,1.69c-.04,3.43-.57,5.99-1.43,7.86-1.54-.69-3.9-2.59-3.9-7.86h-6.13c-.4,8.29,5.08,10.32,7.79,10.82-1.67,1.31-3.75,1.63-5.93,1.37l-.11,4.37c10.33,1.92,15.87-6.83,15.87-16.56h-6.17Z"/>
                                                        <path fill="#f9423a" d="m74.97,0l-4.87,2.17c1.91,4.17,1.52,7.55-2.02,12.12l-2.91-12.6h-6.72l5.39,16.56h6.75c8.02-6.89,8.75-13.62,4.38-18.25Z"/>
                                                        <path fill="#f9423a" d="m16.52,0l-4.87,2.17c1.91,4.17,1.52,7.55-2.02,12.12L6.72,1.69H0l5.39,16.56h6.75C20.16,11.36,20.89,4.63,16.52,0Z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </template>
                                    </div>
                                    <div
                                        v-if="formData.labelTitle"
                                        v-text="formData.labelTitle"
                                        class="choose-badge__view-title"></div>
                                    <div
                                        v-if="formData.labelSubtitle"
                                        v-html="formData.labelSubtitle"
                                        class="choose-badge__view-subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="choose-badge__footer">
                        <div class="choose-badge__error-message">{{$t('tag.error')}}</div>
                        <!--                        <div class="choose-badge__done">Готово</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicOption from "../../classes/DynamicOption";

    export default {
        name: "Label",
        data: () => ({
            pageLang:'',
            store: store,
            labelType: {
                'standard': {
                    title: 'Стандартна',
                    value: 'standard'
                },
                'personal': {
                    title: 'Для себе',
                    value: 'personal'
                },
                'for-person': {
                    title: 'Для когось',
                    value: 'for-person'
                },
                'dedication': {
                    title: 'Присвята (Витвори для...)',
                    value: 'dedication'
                }
            },
            formData: {},
            defaultData: {
                name: '',
                friendName: '',
                gender: '',
                labelType: 'standard',
                labelTitle: '',
                labelSubtitle: ''
            }
        }),
        methods: {
            setLabel(target, clear = false) {
                if (clear === true) {//Очистка всіх полів при зміні типу бірки
                    let labelType = this.formData.labelType
                    this.resetData()
                    this.formData.labelType = labelType
                    store.commit('setSelectedParam', {label: {}})

                }

                let label = new DynamicOption('label', this.formData.labelType, 'Бірка')
                label.slug = 'label'
                label = {
                    ...this.formData,
                    ...label,

                }
                label.selected = this.getActiveLabelText

                store.commit('setSelectedParam', {label})
            },
            resetData() {
                this.formData = Object.assign({}, this.defaultData)
            }
        },
        computed: {
            getActiveLabelText() {
                //хак для реактивності, щоб додати дані при редагуванні продукту
                if (!this.formData.selected && this.getActiveLabel) {
                    this.formData = this.getActiveLabel
                    //По дефолту стандартна
                    if (!this.formData.labelType)
                        this.formData.labelType = "standard"
                }

                let
                    label = store.getters.getSelectedParamsBySteps.Checkout.label ?
                        store.getters.getSelectedParamsBySteps.Checkout.label : {},
                    labelTxt = '',
                    prefix = ''

                if (!label)
                    return labelTxt

                this.formData.labelTitle = this.formData.labelSubtitle = ''
                if (label.labelType === 'standard') {
                    labelTxt = this.$t('tag.labelType.standard');
                } else if (label.labelType === 'personal') {
                    prefix = label.gender === 'male' ? this.$t('tag.maleTag')+' ' : label.gender === 'female' ? this.$t('tag.femaleTag')+' ' : ''
                    labelTxt += prefix + this.formData.name
                    this.formData.labelTitle = this.formData.name

                } else if (label.labelType === 'for-person') {
                    prefix = label.gender === 'male' ? this.$t('tag.maleTag')+' ' : label.gender === 'female' ? this.$t('tag.femaleTag')+' ' : ''
                    this.formData.labelSubtitle = ' '+this.$t('tag.forTag')+' ' + (this.formData.friendName ? this.formData.friendName : '')
                    labelTxt += prefix + this.formData.name + this.formData.labelSubtitle
                    this.formData.labelTitle = this.formData.name

                } else if (label.labelType === 'dedication') {
                    prefix = this.$t('tag.dedicationTag');
                    if(this.pageLang == 'en'){
                        this.formData.labelSubtitle = ' ' + (this.formData.friendName ? this.formData.friendName : '')
                    } else {
                        this.formData.labelSubtitle = ' '+this.$t('tag.forTag')+' ' + (this.formData.friendName ? this.formData.friendName : '')
                    }

                    labelTxt += prefix + this.formData.labelSubtitle
                }

                return labelTxt
            },
            getActiveLabel() {
                return store.getters.getSelectedParamsBySteps.Checkout.label ?
                    store.getters.getSelectedParamsBySteps.Checkout.label : {}
            }
        },
        created() {
            // store.commit('setActiveStep', 'Checkout')
            this.resetData();
            this.pageLang = window.location.href.split("/")[3];

            if(this.pageLang == "en"){
                delete this.labelType["personal"];
                delete this.labelType["for-person"];
            }
        },
    }
</script>
<style>
    #constructor-label .choose-badge__view-label {
        width: 100%;
    }

    .label-sub-dilimetr {
        padding: 0;
        margin: 0;
        text-align: center;
    }
</style>
