<template>
    <div class="constructor-box__list-item">
        <div
            class="constructor-box__list-head collapsed" data-toggle="collapse"
             data-target="#constructor-item-size">
            <div class="constructor-box__list-label required">{{$t('size.title')}}:</div>
            <div v-if="store.getters.getSelectedParamsBySteps.Checkout.size"
                 v-text="getActiveSize"
                 class="constructor-box__list-selected"></div>
            <div v-else class="constructor-box__list-selected">{{$t('size.choose')}}:</div>
            <div class="constructor-box__list-icon"></div>
        </div>
        <div class="constructor-box__list-drop collapse" id="constructor-item-size">
            <div class="constructor-box__list-body">
                <div class="choose-size">
                    <div class="choose-size__aside">
                        <div v-for="sizeParam in sizes[type].params" class="choose-size__item">{{$t(sizeParam)}}:</div>
                    </div>
                    <div class="choose-size__main">
                        <div
                            v-for="size in sizes[type].items"
                            :class="{selected: getActiveSize === size.name}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize(size.name)"
                                    :class="{active: getActiveSize === size.name}"
                                    class="choose-size__check">{{size.name}}
                                </div>
                            </div>
                            <div v-for="sizeVal in size.values" class="choose-size__item">{{sizeVal}}</div>
                        </div>
                    </div>
                </div>
                <span
                    @click="setSize($t('size.my_size'))"
                    class="choose-size__check"
                    style="width: 120px;border-radius: 30px;"
                    :class="{active: getActiveSize === $t('size.my_size')}"
                >{{$t('size.my_size')}}</span>
                <div style="margin-top: 20px" v-if="getActiveSize === $t('size.my_size')">
                    {{$t('size.when_choosing_your_size')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicOption from '../../classes/DynamicOption'

    let match = window.location.href.split("/")[3];
    export default {
        name: "Size",
        data: () => ({
            store: store,
            i18n: i18n,
            type:null,
            sizes:{
                8:{
                    params:['size.params','size.chest_girth','size.waist_girth','size.hip_girth','size.sleeve_length','size.height1','size.height2'],
                    items:[
                        {name:"XXS", values:['100,5','96,6','101,7','65,7','115,8','61,2']},
                        {name:"XS", values:['104,4','100,5','105,6','67,1','116,3','61,5']},
                        {name:"S", values:['108,3','104,4','109,5','68,6','116,8','61,9']},
                        {name:"M", values:['112,3','108,3','113,4','70,1','117,2','62,3']},
                        {name:"L", values:['116,2','112,1','117,3','71,5','117,7','62,7']},
                        {name:"XL", values:['120,1','116,0','121,3','73,0','118,1','63,1']},
                        {name:"XXL", values:['124,1','119,9','125,2','74,5','118,6','63,5']},
                        {name:"XXXL", values:['128,0','123,8','129,1','76,0','119,1','63,9']},
                    ],
                },
                17:{
                    params:['size.params','size.chest_girth','size.waist_girth','size.hip_girth','size.sleeve_length','size.height_from_top_neck'],
                    items:[
                        {name:"XXS", values:['106,5','103,2','100,5','55,2','65,7']},
                        {name:"XS", values:['110,5','107,1','104,4','56,2','67,6']},
                        {name:"S", values:['114,6','111,0','108,4','57,1','69,6']},
                        {name:"M", values:['118,6','115,0','112,3','58,1','71,5']},
                        {name:"L", values:['122,6','118,9','116,3','59,1','73,5']},
                        {name:"XL", values:['126,6','122,8','120,3','60,1','75,5']},
                        {name:"XXL", values:['130,6','126,7','124,2','61,1','77,4']},
                        {name:"XXXL", values:['134,7','130,6','128,2','62,0','79,4']},
                    ],
                }
            }
        }),
        methods: {
            setSize(val) {
                let size = new DynamicOption('size', val, (match == 'en') ? 'Size' : 'Розмір')
                size.slug = 'size';

                if(val == "My size" || val == "Мій розмір"){
                    size.price = this.getOptionMarkupMySize;
                }
                store.commit('setSelectedParam', {size})
            }
        },
        computed: {
            getActiveSize() {
                return store.getters.getSelectedParamsBySteps.Checkout.size ?
                    store.getters.getSelectedParamsBySteps.Checkout.size.value : ''
            },
            getOptionMarkupMySize() {
                return store.getters.getOptionMarkupMySize;
            }
        },
        created() {
            this.type = store.getters.getMockUp.id;
            store.dispatch('loadOptionMarkupMySize');
        }
    }
</script>
