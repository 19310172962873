<template>
    <div>
        <Size v-if="store.getters.getMockUp.type === 'shirts'"></Size>
        <Label></Label>
    </div>
</template>

<script>
    import Size from "./select_type/Size";
    import Label from "./select_type/Label";

    export default {
        name: "ProductComponentsSelection",
        props: ['config', 'mockup', 'translations', 'product_id'],
        components: {
            Size,
            Label
        },
        data: () => ({store: store}),
        created() {
            store.commit('setActiveStep', 'Checkout')
            if (this.mockup){
                store.commit('setMokUp', this.mockup)
            }
            if (this.config)
            // console.log(this.config)
                store.commit('editProduct', this.config)
            if (this.translations)
                store.commit('setTranslations', this.translations)
            if (this.product_id)
                store.commit('setProductId', this.product_id)

        }
    }
</script>
