import mockup from "./mockup";
import mergeImages from "merge-images";

export default {
    state: {
        openCart: false,
        products: [],
        adminEditProduct: null,
        exchange_rate:0,
        option_markup_my_size:0,
    },
    getters: {
        openCart(state) {
            return state.openCart
        },
        getCartProducts(state) {
            return state.products
        },
        getExchangeRate(state){
            return state.exchange_rate
        },
        getOptionMarkupMySize(state){
            return state.option_markup_my_size
        }
    },
    actions: {
        addToCatalog({state}, {index, update}) {
            if (typeof state.products[index] !== 'object')
                return alert('Помилка додавання')
            let product = Object.assign({}, state.products[index])
            let options = []
            product.options.forEach((el, item) => {
                if (el.type === 'size' || el.type === 'label')
                    return false

                options.push(el)
            })
            product.options = options
            product.embroidery_color = store.getters.getEmbroideryColor ? store.getters.getEmbroideryColor.code_option : null
            let event = update ? 'update/' + product.product_id : 'create'
            axios.post('/admin/products/' + event, product)
        },
        loadOptionsTranslations(state, options){
            // console.log(options);
            let options_ids = [];
            options.forEach(opt=>{
                // console.log(opt);
                const item = {parent:"",selected:""};
                if(opt['id']){
                    item.parent = opt['id'];
                    if(opt['selected'] && (opt['type'] != 'images') && (opt['type'] != 'colors')){
                        item.selected = opt['selected']['id'];
                    }
                    options_ids.push(item);
                }


            });
            // console.log({ids:options_ids});
            return axios.post(`/api/get-options-translations`, {ids:options_ids})
                                .then(response => {
                                        return response.data;
                                });
        },
        loadExchangeRate(state){
            return axios.get("/api/get-rate").then(response =>{
                state.commit('setExchangeRate', parseFloat(response.data))
            });
        },
        loadOptionMarkupMySize(state){
            return axios.get("/api/get-option-markup-my-size").then(response =>{
                state.commit('setOptionMarkupMySize', response.data)
            });
        }
    },
    mutations: {
        clearCart(state) {
            state.products = []
            localStorage.removeItem('vytvoryCart')
        },
        deleteItemFromCard(state, index) {
            state.products.splice(index, 1)
            if (state.products.length)
                localStorage.setItem('vytvoryCart', JSON.stringify(state.products))
            else
                localStorage.removeItem('vytvoryCart')
        },
        toggleCart(state) {
            state.openCart = !state.openCart
        },
        productQntPlus(state, index) {
            if (state.products[index].qnt > 99)
                return false

            state.products[index].qnt++
            store.commit('resetCartStorage')
        },
        productQntMinus(state, index) {
            if (state.products[index].qnt < 2)
                return false

            state.products[index].qnt--
            store.commit('resetCartStorage')
        },
        resetCartStorage(state) {
            localStorage.setItem('vytvoryCart', JSON.stringify(state.products))
        },
        initCart(state) {
            try {
                if (localStorage.vytvoryCart) {
                    state.products = JSON.parse(localStorage.vytvoryCart)

                } else
                    state.products = []

                if (state.adminEditProduct) {
                    state.products.push(state.adminEditProduct)
                    state.adminEditProduct = null
                }
            } catch (e) {
                state.products = []
            }
        },
        setAdminEditProduct(state, product) {
            store.commit('initCart')
            state.products.push(product)
            store.commit('resetCartStorage')
            location.href = /*getLocaleFromUrl()+'/constructor/' +*/ product.type + '?m=' + product.mockup_id + '&pid=' + product.product_id + '&edit=' + (state.products.length - 1)
        },
        setExchangeRate(state, rate){
            state.exchange_rate = parseFloat(rate);
        },
        setOptionMarkupMySize(state, rate){
            state.option_markup_my_size = rate;
        },
        async addToCart(state) {
            //Клеїмо превью головного зображення
            let images = [store.getters.getMockUpImg, ...store.getters.getUrlImageVariantUrls],
                compositeMainImage = ''

            await mergeImages(images).then(b64 => compositeMainImage = b64)

            let qnt = 1,
                cartProduct = null,
                adminEdit = false,
                productId = null,
                bimp_uuid = store.state.MockupStore.bimp_uuid,
                product_id = store.state.MockupStore.productId

            if (store.getters.getEditKey && store.getters.getCartProducts[store.getters.getEditKey]) {
                cartProduct = store.getters.getCartProducts[store.getters.getEditKey]
                qnt = cartProduct.qnt
                productId = cartProduct.product_id
                adminEdit = cartProduct.adminEdit

            }

            let product = {
                    pid: product_id,
                    bimp_uuid: bimp_uuid,
                    adminEdit: adminEdit,
                    product_id: productId,
                    title: store.getters.getMockUp.title,
                    mockup_id: store.getters.getMockUp.id,
                    price: parseFloat(store.getters.getMockUp.price),
                    slug: store.getters.getMockUp.slug,
                    type: store.getters.getMockUp.type,
                    img_dir: store.getters.getMockUp.img_dir,
                    preparation_time: store.getters.getMockUp.preparation_time,
                    previewBase64: compositeMainImage,
                    mockup: store.getters.getMockUpImg,
                    sweep: store.getters.getSweepImg,
                    qnt: qnt,
                    product_type: store.getters.getMockUp.product_type,
                    options: [],
                    translations:store.getters.getTranslations
                },
                option,
                param

            for (let opt in store.getters.getSelectedParams) {

                if (typeof store.getters.getSelectedParams[opt] === 'string' && store.getters.getOptionsBySlug[opt]) {
                    param = store.getters.getOptionsBySlug[opt]
                    option = {
                        id: param.id,
                        title: param.title,
                        type: param.type,
                        code: param.code,
                        price: parseFloat(param.price),
                        slug: param.slug,
                        step: param.step,
                        generate_sweep: param.generate_sweep,
                        selected: {
                            id: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].id,
                            title: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].title,
                            type: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].type,
                            code: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].code,
                            price: parseFloat(store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].price),
                            slug: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].slug,
                            step: store.getters.getOptionsBySlug[store.getters.getSelectedParams[opt]].step,
                        }
                    }
                } else if (typeof store.getters.getSelectedParams[opt] === 'object' && store.getters.getOptionsBySlug[opt]) {
                    param = store.getters.getOptionsBySlug[opt]
                    option = {
                        id: param.id,
                        title: param.title,
                        type: param.type,
                        code: param.code,
                        price: parseFloat(param.price),
                        slug: param.slug,
                        step: param.step,
                        generate_sweep: param.generate_sweep,
                        selected: store.getters.getSelectedParams[opt],

                    }
                } else if (typeof store.getters.getSelectedParams[opt] === 'object' && store.getters.getSelectedParams[opt].type === 'size') {
                    param = store.getters.getSelectedParams[opt]
                    option = {
                        id: param.id,
                        title: param.title,
                        type: param.type,
                        code: param.code,
                        price: parseFloat(param.price),
                        slug: param.slug,
                        step: param.step,
                        selected: param.value,
                        value: param.value

                    }
                } else if (typeof store.getters.getSelectedParams[opt] === 'object' && store.getters.getSelectedParams[opt].type === 'label') {
                    option = store.getters.getSelectedParams[opt]
                }

                product.options.push(option)
            }

            await store.dispatch('loadOptionsTranslations', product.options).then(options=>{


                options.forEach((value,index)=>{

                    product.options[index]['translations']=value.parent;
                    if(value.selected){
                        product.options[index]['selected']['translations'] = value.selected;
                    }
                });
            });



            try {
                if (localStorage.vytvoryCart && JSON.parse(localStorage.vytvoryCart).length) {
                    let cart = JSON.parse(localStorage.vytvoryCart)
                    if (cartProduct || adminEdit) {

                        cart[store.getters.getEditKey] = product
                        store.commit('setEditKey', null)
                    } else{

                        cart.push(product)
                    }

                    localStorage.setItem('vytvoryCart', JSON.stringify(cart))
                } else{

                    localStorage.setItem('vytvoryCart', JSON.stringify([product]))
                }

            } catch (e) {
                console.log('catch', e);
                localStorage.setItem('vytvoryCart', JSON.stringify([product]))
            }


            store.commit('initCart')
            store.commit('toggleCart')
        },
        getCartProducts(state) {
            return state.products
        },

    }
}
